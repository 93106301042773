<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>View Audits
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')"
                />
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :loading="loadingFlags.has('fetchAudits')"
                        lazy
                        :value="audits"
                        :total-records="totalRecords"
                        paginator
                        :rows="10"
                        @page="e => { onPage(e); fetchAudits(); }"
                        :pt="{ table: { class: 'table table-bordered table-hover' }}"
                    >
                        <Column
                            field="user.name"
                            header="User"
                        />
                        <Column
                            field="event"
                            header="Event"
                        />
                        <Column
                            field="new_values"
                            header="New Values"
                        >
                            <template #body="{ data }">
                                <ul v-if="data.new_values" class="mb-0">
                                    <li v-for="key in Object.keys(data.new_values)">
                                        <b>{{ key }}</b>: {{ data.new_values[key] ?? '-' }}
                                    </li>
                                </ul>
                            </template>
                        </Column>
                        <Column
                            field="ip_address"
                            header="IP"
                        />
                        <Column
                            field="created_at"
                            header="Happened On"
                        />
                        <template #empty>
                            <div class="text-center">
                                No audits.
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useAudits } from '@/composables/data/audits';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    auditableType: String,
    auditableId: null,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, onPage } = useDataTableParams([
    "user.name",
    "event",
    "new_values",
    "ip_address",
    "created_at",
]);

const { audits, totalRecords, getAudits } = useAudits();

async function fetchAudits() {
    audits.value = null;

    if (!props.isOpen || !props.auditableType || !props.auditableId) {
        return;
    }

    loadingFlags.add('fetchAudits');
    try {
        await getAudits({
            ...params.value,
            auditable_type: encodeURIComponent(props.auditableType),
            auditable_id: props.auditableId,
        });
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchAudits');
}

onMounted(fetchAudits);

watch(() => [props.isOpen, props.auditableType, props.auditableId], fetchAudits);
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
