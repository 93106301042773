<template>
    <div class="row">
        <div class="col-xl-6">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>1. Generated Barcodes (please validate)
                </legend>
                <NewBarcodeOutputsTable
                    :filters="{
                        is_written: {
                            value: false,
                            matchMode: 'equals',
                        },
                    }"
                    :refresh-flag="refreshFlag"
                />
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-gps-fix me-2"></i>2. Select Location for New Bags
                </legend>
                <form @submit.prevent="writeBarcodesToInventory">
                    <DataTable
                        :value="commentDates"
                        paginator
                        :rows="5"
                        filter-display="row"
                        v-model:filters="filters"
                        selection-mode="single"
                        v-model:selection="commentDate"
                        data-key="date"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        class="mb-3">
                        <Column
                            field="date"
                            header="Date"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Date"
                                    @keydown.enter.prevent=""
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No pdates.
                            </div>
                        </template>
                    </DataTable>
                    <FormSelect
                        label="Comment Type"
                        v-model="commentType"
                        is-horizontal
                        :options="commentTypes"
                        id-prefix="commentType"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormSelect
                        label="Comment"
                        v-model="comment"
                        is-horizontal
                        required
                        :options="comments"
                        :option-value="(comment) => comment.COMMENT_ID"
                        :option-to-string="
                            (comment) =>
                                [
                                    comment.COMMENT_ID,
                                    comment.COMMENT_RPT_DATE,
                                    comment.COMMENT_TEXT,
                                    comment.LOCATION,
                                    comment.DESCRIPTION,
                                ].join(' | ')"
                        :errors="errors.COMMENT_ID"
                        class="mb-md-1 mb-2"
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <hr class="my-4" />
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary btn-sm">
                            <i class="icon ph-bold ph-file-plus me-2"></i>Add Barcodes to Database
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
        <div class="col-xl-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>3. Written Barcodes (for printing)
                </legend>
                <NewBarcodeOutputsTable
                    :filters="{
                        is_written: {
                            value: true,
                            matchMode: 'equals',
                        },
                    }"
                    :refresh-flag="refreshFlag"
                />
                <hr class="my-4" />
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="exportWrittenNewBarcodesAsSpreadsheet"
                    >
                        <i class="icon ph-bold ph-file-xls me-2"></i>Export as Spreadsheet
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useCommentDates } from "@/composables/data/commentDates";
import { useCommentTypes } from "@/composables/data/commentTypes";
import { useComments } from "@/composables/data/comments";
import { useNewBarcodeOutputs } from "@/composables/data/newBarcodeOutputs";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import FormSelect from "@/components/utils/FormSelect.vue";
import NewBarcodeOutputsTable from '@/components/utils/tables/NewBarcodeOutputsTable.vue';

import moment from "moment";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams([
    'date',
]);

const { commentDates, getCommentDates } = useCommentDates();
const { commentTypes } = useCommentTypes();
const { comments, getComments } = useComments();

const { postNewBarcodeOutputsMovement } = useNewBarcodeOutputs();

const errors = ref({});

const commentDate = ref(null);
const commentType = ref(null);
const comment = ref(null);

const refreshFlag = ref(false);

async function fetchAvailableCommentDates() {
    commentDates.value = null;

    loadingFlags.add("fetchAvailableCommentDates");
    try {
        await getCommentDates();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchAvailableCommentDates");
}

onMounted(fetchAvailableCommentDates);

async function fetchComments() {
    comments.value = null;
    comment.value = null;

    if (!commentDate.value || !commentType.value) {
        return
    };

    loadingFlags.add("fetchComments");
    try {
        const monthDate = moment.utc(commentDate.value.date, "YYYY/MM");

        await getComments({
            filters: {
                COMMENT_RPT_DATE: {
                    value: [
                        monthDate.startOf("month").format("YYYY-MM-DD"),
                        monthDate.endOf("month").format("YYYY-MM-DD")
                    ],
                    matchMode: "between",
                },
                COMMENT_TEXT: {
                    value: commentType.value.substring(0, 3),
                    matchMode: "startsWith",
                },
                COMMENT_STATUS: {
                    value: "O",
                    matchMode: "equals",
                }
            },
            size: 0,
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchComments");
}

watch([commentDate, commentType], fetchComments);

async function writeBarcodesToInventory() {
    errors.value = {};

    if (window.prompt('Type "PROCEED" to write to inventory.') != "PROCEED") {
        return;
    }

    loadingFlags.add("writeBarcodesToInventory");
    try {
        await postNewBarcodeOutputsMovement(comment.value);
        toasts.add("SUCCESS", "Success", "Successfully wrote barcodes to inventory table.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("writeBarcodesToInventory");
}

function exportWrittenNewBarcodesAsSpreadsheet() {
    window.open(route("api.new-barcode-outputs.written-barcodes-spreadsheet.show"));
}
</script>
