<template>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-gps-fix me-2"></i>New Location for Items
        </legend>
        <div class="row">
            <div class="col-xl-8">
                <div class="row g-3">
                    <div class="col-lg-5 col-3 mb-lg-0 mb-3">
                        <DataTable
                            :value="monthDates"
                            paginator
                            :rows="5"
                            filter-display="row"
                            v-model:filters="filters"
                            selection-mode="single"
                            v-model:selection="monthDate"
                            data-key="date"
                            @row-select="(_) => {
                                comment = null;
                                emit('comment-unselect');
                                fetchComments();
                            }"
                            :pt="{table: { class: 'table table-bordered table-hover' }}"
                        >
                            <Column
                                field="date"
                                header="Date"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Date"
                                        @keydown.enter.prevent=""
                                    />
                                </template>
                            </Column>
                            <template #empty>No dates.</template>
                        </DataTable>
                    </div>
                    <div class="col-lg-7 col-9">
                        <FormInput
                            type="text"
                            v-model="previousLocationDisplay"
                            disabled
                            label="Prev. Loc."
                            id-prefix="previousLocation"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                        />
                        <div class="text-end mb-2">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                @click=" (_) => { isSelectingPreviousLocation = true; }"
                            >
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                            </button>
                        </div>
                        <FormSelect
                            label="Comment Type"
                            v-model="commentMovementType"
                            :options="commentMovementTypes"
                            :option-to-string="option => option.label"
                            id-prefix="commentMovementType"
                            @keydown-enter="focusNextInput('commentFormInput')"
                            select-class="form-select-sm"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            select-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                            @change="onCommentTypeChange"
                        />
                        <FormSelect
                            label="Comment"
                            v-model="comment"
                            :options="comments"
                            :option-to-string="
                                (comment) =>
                                    [
                                        comment.COMMENT_ID,
                                        comment.COMMENT_RPT_DATE,
                                        comment.COMMENT_TEXT,
                                        comment.LOCATION,
                                        comment.DESCRIPTION,
                                    ].join(' | ')
                            "
                            id-prefix="comment"
                            @keydown-enter="focusNextInput('barcodeFormInput')"
                            select-class="form-select-sm"
                            class="mb-lg-1 mb-2"
                            is-horizontal
                            select-container-class="col-xl-8 col-lg-9 col-12"
                            label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                            @change="onCommentChange"
                        />
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <FormInput
                    type="text"
                    label="New Location"
                    v-model="commentLocationDisplay"
                    disabled
                    id-prefix="commentLocationDisplay"
                    input-class="form-control-sm"
                    class="mb-lg-1 mb-2"
                    is-horizontal
                    input-container-class="col-xl-8 col-lg-9 col-12"
                    label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                />
                <FormInput
                    type="text"
                    label="Sale to Employee"
                    v-model="sellToEmployeeIdDisplay"
                    :form-text="sellToEmployee ? `${sellToEmployee.FNAME} ${sellToEmployee.LNAME}` : null"
                    disabled
                    input-class="form-control-sm"
                    class="mb-lg-1 mb-2"
                    is-horizontal
                    input-container-class="col-xl-8 col-lg-9 col-12"
                    label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-outline-secondary btn-sm"
                        @click="reset">
                        <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset
                    </button>
                </div>
            </div>
        </div>
    </fieldset>
    
    <SelectLocationPopup
        :is-open="isSelectingPreviousLocation"
        @select="
            (location) => {
                previousLocation = location;
                isSelectingPreviousLocation = false
                commentMovementType = null;
                comment = null;
                emit('comment-unselect');
            }
        "
        @close="
            (_) => {
                isSelectingPreviousLocation = false;
            }
        "
    />

    <!-- For selling to employees-->
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close=" (_) => { isSelectEmployeePopupOpen = false; }"
        @select="
            (selectedEmployee) => {
                sellToEmployee = selectedEmployee;
                isSelectEmployeePopupOpen = false;
            }
        "
    />

    <!-- Helper popup for finding a comment when returning a bag -->
    <FindCommentOnReturnToLocationPopup 
        :is-open="isFindCommentOnReturnToLocationPopupOpen"
        @close="
            (_) => { 
                isFindCommentOnReturnToLocationPopupOpen = false;
            }
        "
        :comment-date="monthDate?.date"
        @found="
            (foundComment) => {
                comment = foundComment;
                isFindCommentOnReturnToLocationPopupOpen = false;
                emit('comment-select');
            }
        "
    />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useCommentMovementTypes } from '@/composables/data/commentMovementTypes';
import { useComments } from '@/composables/data/comments';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

import FindCommentOnReturnToLocationPopup from './FindCommentOnReturnToLocationPopup.vue';

import moment from 'moment';

const emit = defineEmits(['comment-select', 'comment-unselect']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { filters } = useDataTableParams(['date']);

const monthDates = ref(null);
const monthDate = ref(null);

const previousLocation = defineModel('previousLocation');
const sellToEmployee = defineModel('sellToEmployee');

const { commentMovementTypes } = useCommentMovementTypes();
const commentMovementType = ref(null);

const { comments, getComments } = useComments();
const comment = defineModel();

const previousLocationDisplay = computed(() => previousLocation.value?.LOCATION);
const commentLocationDisplay = computed(() => comment.value?.location.LOCATION);
const sellToEmployeeIdDisplay = computed(() => sellToEmployee.value?.EMPID ?? 0);

const isSelectingPreviousLocation = ref(false);
const isSelectEmployeePopupOpen = ref(false);
const isFindCommentOnReturnToLocationPopupOpen = ref(false);

onMounted(generateMonthDates);

function generateMonthDates() {
    const startDate = moment.utc('2000-01-01');
    const endDate = moment.utc();

    const newMonthDates = [];

    let date = endDate.endOf('month');
    while (date > startDate.startOf('month')) {
        newMonthDates.push({
            date: date.format('YYYY-MM')
        });
        date.subtract(1, 'month');
    }

    monthDates.value = newMonthDates;
}

async function fetchComments() {
    comments.value = null;

    if (!monthDate.value || !commentMovementType.value) {
        return;
    }

    const monthDateObj = moment.utc(monthDate.value.date, 'YYYY-MM');

    loadingFlags.add("fetchComments");
    try {
        await getComments({
            rows: 0, // To fetch all
            filters: {
                COMMENT_RPT_DATE: {
                    value: [
                        monthDateObj.startOf('month').format('YYYY-MM-DD'),
                        monthDateObj.endOf('month').format('YYYY-MM-DD'),
                    ],
                    matchMode: 'between',
                },
                MOVEMENT_TYPE: {
                    value: commentMovementType.value.value,
                    matchMode: 'equals',
                },
                COMMENT_STATUS: {
                    value: 'O',
                    matchMode: 'equals',
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchComments");
}

async function onCommentTypeChange() {
    comment.value = null;
    emit('comment-unselect');
    await fetchComments();

    sellToEmployee.value = null;

    if (
        !previousLocation.value ||
        !commentMovementType.value
    ) {
        return;
    }
    
    if (
        previousLocation.value.LOCATION == 'SOLD' &&
        commentMovementType.value.value == 'RETURN'
    ) {
        isFindCommentOnReturnToLocationPopupOpen.value = true;
    } else if (
        previousLocation.value.LOCATION == 'MAIN_1' &&
        commentMovementType.value.value == 'SALES'
    ) {
        isSelectEmployeePopupOpen.value = true;
    }
}

function onCommentChange() {
    if (
        !previousLocation.value ||
        !comment.value
    ) {
        return;
    }

    if (
        previousLocation.value?.LOCATION ==
        comment.value?.LOCATION
    ) {
        toasts.add(
            'ERROR',
            'Error',
            'Cannot specify that comment, locations will be same.'
        );
        comment.value = null;
    }

    emit('comment-select');
}

function reset() {
    monthDate.value = null;
    previousLocation.value = null;
    commentMovementType.value = null;
    comment.value = null;
    sellToEmployee.value = null;
    emit('comment-unselect');
}

async function initialize(initialPreviousLocation, initialSellToEmployee, initialComment) {
    await generateMonthDates();
    monthDate.value = {
        date: moment(initialComment.COMMENT_RPT_DATE).format('YYYY-MM')
    };
    previousLocation.value = initialPreviousLocation;
    commentMovementType.value = commentMovementTypes.filter(
        (currentCommentMovementType) => currentCommentMovementType.value == initialComment.MOVEMENT_TYPE
    )[0] ?? null;
    await fetchComments();
    comment.value = comments.value.filter(
        (currentComment) => currentComment.COMMENT_ID == initialComment.COMMENT_ID
    )[0] ?? null;
    sellToEmployee.value = initialSellToEmployee;
}

defineExpose({
    initialize,
});
</script>
