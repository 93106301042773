import axios from 'axios';

import constructFetchError from "./constructFetchError";
import { ref } from 'vue';

export function useSessionBarcodes() {
    const movement = ref(null);

    const getSessionBarcodes = async () => {
        try {
            const response = await axios.get(route('api.inventory.session-barcodes.show'));
            movement.value = response.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while retrieving movement from session.');
        }
    };

    const putItems = async (previousLocationText, sellToId, commentId, items) => {
        try {
            await axios.put(route('api.inventory.session-barcodes.items.update'), {
                PREV_LOC: previousLocationText,
                SELL_TO_ID: sellToId,
                COMMENT_ID: commentId,
                items
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while setting items in session.');
        }
    };
    
    const destroyItems = async () => {
        try {
            await axios.delete(route('api.inventory.session-barcodes.items.destroy'));
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while removing items from session.');
        }
    };
    
    return {
        movement,
        getSessionBarcodes,
        putItems,
        destroyItems,
    };
}
