<style scoped>
.table-container {
    overflow: auto;
    overflow: auto;
    height: 140px;
    background: #fff;
}

.table-container thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}
</style>

<template>
    <div class="row g-3 mb-1">
        <div class="col-xl-4">
            <fieldset class="mb-2">
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Open Time Periods
                </legend>
                <TimePeriodTable
                    v-model="selectedTimePeriod"
                    :refresh-flag="timePeriodsTableRefreshFlag"
                    :filters="{
                        STATUS: {
                            value: 'O',
                            matchMode: 'equals',
                        },
                        PAYTYPE: {
                            value: ['WEEKLY', 'BIMONTHLY'],
                            matchMode: 'in',
                        },
                    }"
                    @error="
                        (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }
                    "
                />
            </fieldset>
            <EmployeeSelectionTableCard
                v-model="selectedEmployee"
                :time-period="selectedTimePeriod?.TIME_PERIOD"
                :refresh-flag="employeesTableRefreshFlag"
                @error="onError"
            />
        </div>
        <div class="col-xl-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calculator me-2"></i>Employee Share Deficit
                </legend>
                <div class="row gx-5">
                    <div class="col-xl-6">
                        <div class="row mb-md-1 mb-2">
                            <label for="paytypeDisplay" class="col-md-3 col-form-label label-sm align-items-center">Pay Type</label>
                            <div class="col-md-7">
                                <input
                                    id="paytypeDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="selectedTimePeriod?.PAYTYPE"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="startDateDisplay" class="col-md-3 col-form-label label-sm align-items-center">Start Date</label>
                            <div class="col-md-7">
                                <input
                                    id="startDateDisplay"
                                    type="date"
                                    class="col form-control form-control-sm"
                                    :value="selectedTimePeriod?.START_DATE"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="endDateDisplay" class="col-md-3 col-form-label label-sm align-items-center">End Date</label>
                            <div class="col-md-7">
                                <input
                                    id="endDateDisplay"
                                    type="date"
                                    class="col form-control form-control-sm"
                                    :value="selectedTimePeriod?.END_DATE"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="daysAbsentDisplay" class="col-md-3 col-form-label label-sm align-items-center">Days Absent</label>
                            <div class="col-md-7">
                                <input
                                    id="daysAbsentDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="payslip?.ABSENT_DAYS"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="actSSSEmpDisplay" class="col-md-3 col-form-label label-sm align-items-center">ACT SSS EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="actSSSEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(
                                        (payslip?.RATE_OBJ?.SSS_CONTRIBUTION_EMP ?? 0) +
                                        (payslip?.RATE_OBJ?.SSS_CONTRIBUTION_MPF_EMP ?? 0) +
                                        (payslip?.RATE_OBJ?.SSS_CONTRIBUTION_EC_EMP ?? 0)
                                    )"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="actPHEmpDisplay" class="col-md-3 col-form-label label-sm align-items-center">ACT PH EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="actPHEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.RATE_OBJ?.PH_CONTRIBUTION ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="actPIEmpDisplay" class="col-md-3 col-form-label label-sm align-items-center">ACT PI EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="actPIEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.RATE_OBJ?.PAGIBIG_CONTRIBUTION_EMP ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="row mb-md-1 mb-2">
                            <label for="calculatedSalaryDisplay" class="col-xl-4 col-md-3 col-form-label label-sm align-items-center">CALC SALARY</label>
                            <div class="col-md-7">
                                <input
                                    id="calculatedSalaryDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.CALCULATED_SALARY ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="calculatedSSSEmpDisplay" class="col-xl-4 col-md-3 col-form-label label-sm align-items-center">CALC SSS EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="calculatedSSSEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.CALCULATED_SSS_EMP ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="calculatedPHEmpDisplay" class="col-xl-4 col-md-3 col-form-label label-sm align-items-center">CALC PH EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="calculatedPHEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.CALCULATED_PH_EMP ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="calculatedPIEmpDisplay" class="col-xl-4 col-md-3 col-form-label label-sm align-items-center">CALC PI EMP</label>
                            <div class="col-md-7">
                                <input
                                    id="calculatedPIEmpDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="roundTwoDecimalPlaces(payslip?.CALCULATED_PI_EMP ?? 0)"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="row g-3">
                <div class="col-md-8">
                    <fieldset class="h-100">
                        <legend>
                            <i class="icon ph-bold ph-clock-user me-2"></i>Employee Hours
                        </legend>
                        <div class="table-container">
                            <table class="table table-bordered table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>Jono</th>
                                        <th>Style</th>
                                        <th>Rate</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="hoursOnJoborder in allHoursPerJoborder">
                                        <td>{{ hoursOnJoborder.JONO }}</td>
                                        <td>{{ hoursOnJoborder.STYLE }}</td>
                                        <td>{{ hoursOnJoborder.RATE }}</td>
                                        <td>{{ roundTwoDecimalPlaces(hoursOnJoborder.HOURS) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-4">
                    <fieldset class="h-100">
                        <legend>
                            <i class="icon ph-bold ph-island me-2"></i>Leaves Taken This Year
                        </legend>
                        <div class="row mb-md-1 mb-2">
                            <label for="incentiveSalesHoursDisplay" class="col-md-5 col-form-label label-sm">Incentive (Sales)</label>
                            <div class="col-md-7">
                                <input
                                    id="incentiveSalesHoursDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="incentiveSalesHours"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="incentiveRegularHoursDisplay" class="col-md-5 col-form-label label-sm">Incentive (Reg)</label>
                            <div class="col-md-7">
                                <input
                                    id="incentiveRegularHoursDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="incentiveRegularHours"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="absentHoursDisplay" class="col-md-5 col-form-label label-sm">Absent</label>
                            <div class="col-md-7">
                                <input
                                    id="absentHoursDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="absentHours"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="suspendedHoursDisplay" class="col-md-5 col-form-label label-sm">Suspended</label>
                            <div class="col-md-7">
                                <input
                                    id="suspendedHoursDisplay"
                                    type="text"
                                    class="col form-control form-control-sm"
                                    :value="suspendedHours"
                                    disabled
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
    <OpenLoansTableCard
        :employee="selectedEmployee"
        :refresh-flag="loansRefreshFlag"
        class="mb-1"
    />
    <div class="row g-3">
        <div class="col-md-4">
            <fieldset class="mb-3">
                <legend><i class="icon ph-bold ph-plus me-2"></i>Add</legend>
                <div class="row mb-md-1 mb-2">
                    <label for="honorariumDisplay" class="col-md-4 col-form-label label-sm align-items-center">Honorarium</label>
                    <div class="col-md-8">
                        <input
                            ids="honorariumDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.HONORARIUM ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.HONORARIUM ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="incentiveRegularAddDisplay" class="col-md-4 col-form-label label-sm align-items-center">Incentive (Reg)</label>
                    <div class="col-md-8">
                        <input
                            id="incentiveRegularAddDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.INCENTIVE_REG ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.INCENTIVE_REG ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="incentiveSalesAddDisplay" class="col-md-4 col-form-label label-sm align-items-center">Incentive (Sales)</label>
                    <div class="col-md-8">
                        <input
                            id="incentiveSalesAddDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${  payslip?.INCENTIVE_SALES ?? 0 }`"
                            :value="roundTwoDecimalPlaces(payslip?.INCENTIVE_SALES ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="regularSalaryDisplay" class="col-md-4 col-form-label label-sm align-items-center">Regular Salary</label>
                    <div class="col-md-8">
                        <input
                            id="regularSalaryDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.REGULAR_SALARY ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.REGULAR_SALARY ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="COLADisplay" class="col-md-4 col-form-label label-sm align-items-center">COLA</label>
                    <div class="col-md-8">
                        <input
                            id="COLADisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.COLA ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.COLA ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="overtimeSalaryDisplay" class="col-md-4 col-form-label label-sm align-items-center">Overtime Salary</label>
                    <div class="col-md-8">
                        <input
                            id="overtimeSalaryDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${ payslip?.OVERTIME_SALARY ?? 0 }`"
                            :value="roundTwoDecimalPlaces(payslip?.OVERTIME_SALARY ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="extraSalaryDisplay" class="col-md-4 col-form-label label-sm align-items-center">Extra Salary</label>
                    <div class="col-md-8">
                        <input
                            id="extraSalaryDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.EXTRA_SALARY ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.EXTRA_SALARY ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="disbursementsDisplay" class="col-md-4 col-form-label label-sm align-items-center">Disbursements</label>
                    <div class="col-md-8">
                        <input
                            id="disbursementsDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.DISBURSEMENT ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.DISBURSEMENT ?? 0)"
                            disabled
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-credit-card me-2"></i>Final Checkout Amount
                </legend>
                <div class="row mb-md-1 mb-2">
                    <label for="CAOffsetDisplay" class="col-md-4 col-form-label label-sm align-items-center">CA Offset</label>
                    <div class="col-md-8">
                        <input
                            id="CAOffsetDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${caOffset ?? 0}`"
                            :value="roundTwoDecimalPlaces(caOffset ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="totalAddDisplay" class="col-md-4 col-form-label label-sm align-items-center">Gross Pay</label>
                    <div class="col-md-8">
                        <input
                            id="totalAddDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${grossPay ?? 0}`"
                            :value="roundTwoDecimalPlaces(grossPay ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="missedContributionsDisplay" class="col-md-4 col-form-label label-sm align-items-center">Missed Contributions</label>
                    <div class="col-md-8">
                        <input
                            id="missedContributionsDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${payslip?.MISSED_CONTRIB ?? 0}`"
                            :value="roundTwoDecimalPlaces(payslip?.MISSED_CONTRIB ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="totalDeductDisplay" class="col-md-4 col-form-label label-sm align-items-center">Total Deduct</label>
                    <div class="col-md-8">
                        <input
                            id="totalDeductDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${totalDeduct ?? 0}`"
                            :value="roundTwoDecimalPlaces(totalDeduct ?? 0)"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mb-md-1 mb-2">
                    <label for="finalDisplay" class="col-md-4 col-form-label label-sm align-items-center"><strong>FINAL</strong></label>
                    <div class="col-md-8">
                        <input
                            id="finalDisplay"
                            type="text"
                            class="col form-control form-control-sm"
                            :title="`Actual is ${finalCheckoutAmount ?? 0}`"
                            :value="roundTwoDecimalPlaces(finalCheckoutAmount ?? 0)"
                            disabled
                        />
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-md-8">
            <fieldset class="mb-md-1 mb-3">
                <legend>
                    <i class="icon ph-bold ph-minus me-2"></i>Subtract
                </legend>
                <div class="subtract-section-multiple-input-container">
                    <div class="row mb-md-1 mb-2 align-items-center">
                        <div class="col-2">C/A</div>
                        <div class="col px-1" v-for="(payment, index) in deductionInputs.CA_LOAN_PAYMENTS">
                            <div class="row g-1 align-items-center">
                                <div class="col-5">
                                    <label :for="'caLoanPayment' + index" class="loan-id-label label-sm align-items-center">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-7">
                                    <input
                                        type="number"
                                        step=".01"
                                        v-model="payment.PAYMENT"
                                        :id="'caLoanPayment' + index"
                                        class="form-control form-control-sm"
                                        :title="`Actual is ${ actualDeductions.CA_LOAN_PAYMENTS[index].PAYMENT ?? 0 }`"
                                        @keyup="(_) => { actualDeductions.CA_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @change="(_) => { actualDeductions.CA_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="
                                            (e) => {
                                                if (index == deductionInputs.CA_LOAN_PAYMENTS.length - 1) {
                                                    focusNextInput('itemLoanPayment0');
                                                } else {
                                                    focusNextInput('caLoanPayment' + (index + 1));
                                                }
                                            }
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2 align-items-center">
                        <div class="col-2">Items</div>
                        <div class="col px-1" v-for="(payment, index) in deductionInputs.ITEM_LOAN_PAYMENTS">
                            <div class="row g-1 align-items-center">
                                <div class="col-5">
                                    <label :for="'itemLoanPayment' + index" class="loan-id-label label-sm align-items-center">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-7">
                                    <input
                                        type="number"
                                        step=".01"
                                        v-model="payment.PAYMENT"
                                        :id="'itemLoanPayment' + index"
                                        class="form-control form-control-sm"
                                        :title="`Actual is ${ actualDeductions.ITEM_LOAN_PAYMENTS[index].PAYMENT ?? 0 }`"
                                        @keyup="(_) => { actualDeductions.ITEM_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @change="(_) => { actualDeductions.ITEM_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="
                                            (e) => {
                                                if (index == deductionInputs.ITEM_LOAN_PAYMENTS.length - 1) {
                                                    focusNextInput('missingLoanPayment0');
                                                } else {
                                                    focusNextInput('itemLoanPayment' + (index + 1));
                                                }
                                            }
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-md-1 mb-2 align-items-center">
                        <div class="col-2">Missing</div>
                        <div class="col px-1" v-for="(payment, index) in deductionInputs.MISSING_LOAN_PAYMENTS">
                            <div class="row g-1 align-items-center">
                                <div class="col-5">
                                    <label :for="'missingLoanPayment' + index" class="loan-id-label label-sm align-items-center">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-7">
                                    <input
                                        type="number"
                                        step=".01"
                                        v-model="payment.PAYMENT"
                                        :id="'missingLoanPayment' + index"
                                        class="form-control form-control-sm"
                                        :title="`Actual is ${ actualDeductions.MISSING_LOAN_PAYMENTS[index].PAYMENT ?? 0 }`"
                                        @keyup="(_) => { actualDeductions.MISSING_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @change="(_) => { actualDeductions.MISSING_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="
                                            (e) => {
                                                if (index == deductionInputs.MISSING_LOAN_PAYMENTS.length - 1) {
                                                    focusNextInput('kimbelLoanPayment0');
                                                } else {
                                                    focusNextInput('missingLoanPayment' + (index + 1));
                                                }
                                            }
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row mb-md-1 mb-2 align-items-center">
                        <div class="col-2">Kimbel</div>
                        <div class="col px-1" v-for="(payment, index) in deductionInputs.KIMBEL_LOAN_PAYMENTS">
                            <div class="row g-1 align-items-center">
                                <div class="col-5">
                                    <label :for="'kimbelLoanPayment' + index" class="loan-id-label label-sm align-items-center">
                                        {{ payment.LOANID }}
                                    </label>
                                </div>
                                <div class="col-7">
                                    <input
                                        type="number"
                                        step=".01"
                                        v-model="payment.PAYMENT"
                                        :id="'kimbelLoanPayment' + index"
                                        class="form-control form-control-sm"
                                        :title="`Actual is ${ actualDeductions.KIMBEL_LOAN_PAYMENTS[index].PAYMENT ?? 0 }`"
                                        @keyup="(_) => { actualDeductions.KIMBEL_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @change="(_) => { actualDeductions.KIMBEL_LOAN_PAYMENTS[index].PAYMENT = payment.PAYMENT; }"
                                        @keydown.enter.prevent="
                                            (e) => {
                                                if (index == deductionInputs.KIMBEL_LOAN_PAYMENTS.length - 1) {
                                                    focusNextInput('eloadInput');
                                                } else {
                                                    focusNextInput('kimbelLoanPayment' + (index + 1));
                                                }
                                            }
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-md-1 mb-2 gx-5">
                    <div class="col-xl-6">
                        <FormInput
                            label="E-LOAD"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.ELOAD_PAYMENT"
                            :title="`Actual is ${ actualDeductions.ELOAD_PAYMENT ?? 0 }`"
                            :is-horizontal="true"
                            id-prefix="eloadPayment"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.ELOAD_PAYMENT = deductionInputs.ELOAD_PAYMENT; }"
                            @change="(_) => { actualDeductions.ELOAD_PAYMENT = deductionInputs.ELOAD_PAYMENT; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS CONT"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CONTRIBUTION"
                            :title="`Actual is ${ actualDeductions.SSS_CONTRIBUTION ?? 0 }`"
                            id-prefix="sssContribution"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CONTRIBUTION = deductionInputs.SSS_CONTRIBUTION; }"
                            @change="(_) => { actualDeductions.SSS_CONTRIBUTION = deductionInputs.SSS_CONTRIBUTION; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS MPF"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CONTRIBUTION_MPF"
                            :title="`Actual is ${ actualDeductions.SSS_CONTRIBUTION_MPF ?? 0 }`"
                            id-prefix="sssContributionMpf"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CONTRIBUTION_MPF = deductionInputs.SSS_CONTRIBUTION_MPF; }"
                            @change="(_) => { actualDeductions.SSS_CONTRIBUTION_MPF = deductionInputs.SSS_CONTRIBUTION_MPF; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS EMP CONT"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CONTRIBUTION_EMP"
                            :title="`Actual is ${ actualDeductions.SSS_CONTRIBUTION_EMP ?? 0 }`"
                            id-prefix="sssContributionEmp"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CONTRIBUTION_EMP = deductionInputs.SSS_CONTRIBUTION_EMP; }"
                            @change="(_) => { actualDeductions.SSS_CONTRIBUTION_EMP = deductionInputs.SSS_CONTRIBUTION_EMP; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS MPF EMP"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CONTRIBUTION_MPF_EMP"
                            :title="`Actual is ${ actualDeductions.SSS_CONTRIBUTION_MPF_EMP ?? 0 }`"
                            id-prefix="sssContributionMpfEmp"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CONTRIBUTION_MPF_EMP = deductionInputs.SSS_CONTRIBUTION_MPF_EMP; }"
                            @change="(_) => { actualDeductions.SSS_CONTRIBUTION_MPF_EMP = deductionInputs.SSS_CONTRIBUTION_MPF_EMP; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS EC EMP"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CONTRIBUTION_EC_EMP"
                            :title="`Actual is ${ actualDeductions.SSS_CONTRIBUTION_EC_EMP ?? 0 }`"
                            id-prefix="sssContributionEcEmp"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CONTRIBUTION_EC_EMP = deductionInputs.SSS_CONTRIBUTION_EC_EMP; }"
                            @change="(_) => { actualDeductions.SSS_CONTRIBUTION_EC_EMP = deductionInputs.SSS_CONTRIBUTION_EC_EMP; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="PAGIBIG CONT"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.PAGIBIG_CONTRIBUTION"
                            :title="`Actual is ${ actualDeductions.PAGIBIG_CONTRIBUTION ?? 0 }`"
                            id-prefix="pagibigContribution"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.PAGIBIG_CONTRIBUTION = deductionInputs.PAGIBIG_CONTRIBUTION; }"
                            @change="(_) => { actualDeductions.PAGIBIG_CONTRIBUTION = deductionInputs.PAGIBIG_CONTRIBUTION; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="PAGIBIG EMP CONT"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.PAGIBIG_CONTRIBUTION_EMP"
                            :title="`Actual is ${ actualDeductions.PAGIBIG_CONTRIBUTION_EMP ?? 0 }`"
                            id-prefix="pagibigContributionEmp"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.PAGIBIG_CONTRIBUTION_EMP = deductionInputs.PAGIBIG_CONTRIBUTION_EMP; }"
                            @change="(_) => { actualDeductions.PAGIBIG_CONTRIBUTION_EMP = deductionInputs.PAGIBIG_CONTRIBUTION_EMP; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="PH CONT"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.PH_CONTRIBUTION"
                            :title="`Actual is ${ actualDeductions.PH_CONTRIBUTION ?? 0 }`"
                            id-prefix="phContribution"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.PH_CONTRIBUTION = deductionInputs.PH_CONTRIBUTION; }"
                            @change="(_) => { actualDeductions.PH_CONTRIBUTION = deductionInputs.PH_CONTRIBUTION; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="WTX"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.WTX"
                            :title="`Actual is ${ actualDeductions.WTX ?? 0 }`"
                            id-prefix="wtx"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.WTX = deductionInputs.WTX; }"
                            @change="(_) => { actualDeductions.WTX = deductionInputs.WTX; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                    </div>
                    <div class="col-xl-6">
                        <FormInput
                            label="SSS Loan"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_LOAN_PAYMENT"
                            :title="`Actual is ${ actualDeductions.SSS_LOAN_PAYMENT ?? 0 }`"
                            id-prefix="sssLoanPayment"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_LOAN_PAYMENT = deductionInputs.SSS_LOAN_PAYMENT; }"
                            @change="(_) => { actualDeductions.SSS_LOAN_PAYMENT = deductionInputs.SSS_LOAN_PAYMENT; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="SSS Calamity"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.SSS_CALAMITY_LOAN_PAYMENT"
                            :title="`Actual is ${ actualDeductions.SSS_CALAMITY_LOAN_PAYMENT ?? 0 }`"
                            id-prefix="sssLoanPayment"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.SSS_CALAMITY_LOAN_PAYMENT = deductionInputs.SSS_CALAMITY_LOAN_PAYMENT; }"
                            @change="(_) => { actualDeductions.SSS_CALAMITY_LOAN_PAYMENT = deductionInputs.SSS_CALAMITY_LOAN_PAYMENT; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="PAGIBIG Loan"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.PAGIBIG_LOAN_PAYMENT"
                            :title="`Actual is ${ actualDeductions.PAGIBIG_LOAN_PAYMENT ?? 0 }`"
                            id-prefix="pagibigLoanPayment"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.PAGIBIG_LOAN_PAYMENT = deductionInputs.PAGIBIG_LOAN_PAYMENT; }"
                            @change="(_) => { actualDeductions.PAGIBIG_LOAN_PAYMENT = deductionInputs.PAGIBIG_LOAN_PAYMENT; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="PAGIBIG Calamity"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.PAGIBIG_CALAMITY_LOAN_PAYMENT"
                            :title="`Actual is ${ actualDeductions.PAGIBIG_CALAMITY_LOAN_PAYMENT ?? 0 }`"
                            id-prefix="pagibigCalamityLoanPayment"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.PAGIBIG_CALAMITY_LOAN_PAYMENT = deductionInputs.PAGIBIG_CALAMITY_LOAN_PAYMENT; }"
                            @change="(_) => { actualDeductions.PAGIBIG_CALAMITY_LOAN_PAYMENT = deductionInputs.PAGIBIG_CALAMITY_LOAN_PAYMENT; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <FormInput
                            label="Insurance"
                            type="number"
                            step=".01"
                            v-model="deductionInputs.INSURANCE"
                            :title="`Actual is ${ actualDeductions.INSURANCE ?? 0 }`"
                            id-prefix="insurance"
                            is-horizontal
                            class="mb-1"
                            label-class="col-xl-4 col-md-5 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            @keyup="(_) => { actualDeductions.INSURANCE = deductionInputs.INSURANCE; }"
                            @change="(_) => { actualDeductions.INSURANCE = deductionInputs.INSURANCE; }"
                            @keydown-enter="focusNextInput('daysOfWorkFormInput')"
                        />
                        <div class="row g-1 text-end">
                            <span class="form-check d-flex justify-content-end align-items-center">
                                <label for="includeDeductionsInput" class="form-check-label me-2">Include Deductions</label>
                                <input
                                    type="checkbox"
                                    v-model="includeDeductions"
                                    id="includeDeductionsInput"
                                    aria-label="Checkbox for including deductions"
                                    checked
                                    @change="initializeSubtractSection"
                                    @keydown.enter.prevent="focusNextInput('createPayslipButton')"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-money-wavy me-2"></i>Rate
                </legend>
                <div class="row">
                    <div class="col-xl-8">
                        <div class="row mb-md-1 mb-2">
                            <label for="dailyRateDisplay" class="col-md-5 col-form-label label-sm align-items-center">Daily Rate</label>
                            <div class="col-md-7">
                                <input
                                    type="text"
                                    class="col form-control form-control-sm"
                                    id="dailyRateDisplay"
                                    :value="payslip?.RATE"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="hourlyRateDisplay" class="col-md-5 col-form-label label-sm align-items-center">Hourly Rate</label>
                            <div class="col-md-7">
                                <input
                                    type="text"
                                    class="col form-control form-control-sm"
                                    id="hourlyRateDisplay"
                                    :value="payslip?.HOURLY_RATE"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="row mb-md-1 mb-2">
                            <label for="hourlyCOLADisplay" class="col-md-5 col-form-label label-sm align-items-center">Hourly COLA</label>
                            <div class="col-md-7">
                                <input
                                    type="text"
                                    class="col form-control form-control-sm"
                                    id="hourlyCOLADisplay"
                                    :value="payslip?.HOURLY_COLA_RATE"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <button type="submit"
                            class="btn btn-primary h-100 w-100 btn-sm"
                            :disabled="loadingFlags.has('submitPayslip') || isDisabled"
                            id="createPayslipButton"
                            @click="submitForm">
                            <span v-if="loadingFlags.has('submitPayslip')"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-receipt me-2"></i>Create Payslip
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useFlashMessages } from "@/composables/flashmessages";
import axios from "axios";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable";
import EmployeeSelectionTableCard from "./EmployeeSelectionTableCard";
import OpenLoansTableCard from "./OpenLoansTableCard";
import roundTwoDecimalPlaces from "@/utils/roundTwoDecimalPlaces";
import focusNextInput from "@/utils/focusnext";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";
import FormInput from "@/components/utils/FormInput.vue";

const { addFlashMessage } = useFlashMessages();

const loadingFlags = useLoadingFlagsStore();

const timePeriodsTableRefreshFlag = ref(false);
const employeesTableRefreshFlag = ref(false);

const selectedTimePeriod = ref(null);
const selectedEmployee = ref(null);
const payslip = ref(null);

const loansRefreshFlag = ref(false);

const isDisabled = ref(true);

const onError = (message) => {
    addFlashMessage('ERROR', message);
    scrollToTop();
}

const deductionInputs = ref({
    CA_LOAN_PAYMENTS: null,
    ITEM_LOAN_PAYMENTS: null,
    MISSING_LOAN_PAYMENTS: null,
    KIMBEL_LOAN_PAYMENTS: null,
    ELOAD_PAYMENT: 0,
    SSS_CONTRIBUTION: 0,
    SSS_CONTRIBUTION_MPF: 0,
    SSS_LOAN_PAYMENT: 0,
    SSS_CALAMITY_LOAN_PAYMENT: 0,
    SSS_CONTRIBUTION_EMP: 0,
    SSS_CONTRIBUTION_MPF_EMP: 0,
    SSS_CONTRIBUTION_EC_EMP: 0,
    PAGIBIG_LOAN_PAYMENT: 0,
    PAGIBIG_CALAMITY_LOAN_PAYMENT: 0,
    PAGIBIG_CONTRIBUTION: 0,
    PAGIBIG_CONTRIBUTION_EMP: 0,
    INSURANCE: 0,
    PH_CONTRIBUTION: 0,
    WTX: 0,
});

const actualDeductions = ref({
    CA_LOAN_PAYMENTS: null,
    ITEM_LOAN_PAYMENTS: null,
    MISSING_LOAN_PAYMENTS: null,
    KIMBEL_LOAN_PAYMENTS: null,
    ELOAD_PAYMENT: 0,
    SSS_CONTRIBUTION: 0,
    SSS_CONTRIBUTION_MPF: 0,
    SSS_LOAN_PAYMENT: 0,
    SSS_CALAMITY_LOAN_PAYMENT: 0,
    SSS_CONTRIBUTION_EMP: 0,
    SSS_CONTRIBUTION_MPF_EMP: 0,
    SSS_CONTRIBUTION_EC_EMP: 0,
    PAGIBIG_LOAN_PAYMENT: 0,
    PAGIBIG_CALAMITY_LOAN_PAYMENT: 0,
    PAGIBIG_CONTRIBUTION: 0,
    PAGIBIG_CONTRIBUTION_EMP: 0,
    INSURANCE: 0,
    PH_CONTRIBUTION: 0,
    WTX: 0,
});

const includeDeductions = ref(true);

const incentiveSalesHours = computed(() => {
    if (!payslip.value) {
        return null;
    }

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "INCENTIVE (SALES)")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const incentiveRegularHours = computed(() => {
    if (!payslip.value) {
        return null;
    }

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "INCENTIVE (REG)")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const absentHours = computed(() => {
    if (!payslip.value) {
        return null;
    }

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "ABSENT")
        .reduce((totalhours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const suspendedHours = computed(() => {
    if (!payslip.value) {
        return null;
    }

    return payslip.value.HOURS_LIST
        .filter(hoursOnDate => hoursOnDate.STYLE === "SUSPENDED")
        .reduce((totalHours, hoursOnDate) => totalHours + hoursOnDate.HOURS, 0);
});

const allHoursPerJoborder = computed(() => {
    if (!payslip.value) {
        return null;
    }

    return payslip.value.HOURS_LIST.reduce((allHoursPerJoborder, hoursOnDate) => {
        const newAllHoursPerJoborder = [ ...allHoursPerJoborder ];

        const joborderHours = newAllHoursPerJoborder.filter((hoursOnJoborder) => {
            return hoursOnJoborder.JONO === hoursOnDate.JONO &&
                hoursOnJoborder.STYLE === hoursOnDate.STYLE &&
                hoursOnJoborder.RATE === hoursOnDate.RATE;
        })[0];

        if (joborderHours != undefined) {
            joborderHours.HOURS += hoursOnDate.HOURS;
        } else {
            newAllHoursPerJoborder.push({
                JONO: hoursOnDate.JONO,
                STYLE: hoursOnDate.STYLE,
                RATE: hoursOnDate.RATE,
                HOURS: hoursOnDate.HOURS
            });
        }

        return newAllHoursPerJoborder;
    }, []);
});

const grossPay = computed(() =>
    payslip.value ? payslip.value.GROSS_PAY + (caOffset.value > 0 ? caOffset.value : 0) : 0
);

const totalDeduct = computed(() => {
    const paymentTotalReducer = (total, payment) => total + (payment.PAYMENT || 0);

    return (actualDeductions.value.CA_LOAN_PAYMENTS?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.ITEM_LOAN_PAYMENTS?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.MISSING_LOAN_PAYMENTS?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.KIMBEL_LOAN_PAYMENTS?.reduce(paymentTotalReducer, 0) || 0) +
        (actualDeductions.value.ELOAD_PAYMENT || 0) +
        (actualDeductions.value.SSS_CONTRIBUTION || 0) +
        (actualDeductions.value.SSS_CONTRIBUTION_MPF || 0) +
        (actualDeductions.value.SSS_LOAN_PAYMENT || 0) +
        (actualDeductions.value.SSS_CALAMITY_LOAN_PAYMENT || 0) +
        (actualDeductions.value.PAGIBIG_LOAN_PAYMENT || 0) +
        (actualDeductions.value.PAGIBIG_CALAMITY_LOAN_PAYMENT || 0) +
        (actualDeductions.value.PAGIBIG_CONTRIBUTION || 0) +
        (actualDeductions.value.PH_CONTRIBUTION || 0) +
        (actualDeductions.value.INSURANCE || 0) +
        (actualDeductions.value.WTX || 0) +
        (payslip.value?.MISSED_CONTRIB ?? 0);
});

const finalAmount = computed(() =>
    payslip.value ? payslip.value.GROSS_PAY - totalDeduct.value : 0
);

const finalCheckoutAmount = computed(() =>
    finalAmount.value >= 0 ? finalAmount.value : 0
);

const caOffset = computed(() =>
    finalAmount.value < 0 ?
        -finalAmount.value : 0
);

async function initializePayslipForSelectedEmployee() {
    isDisabled.value = true;

    payslip.value = null;
    actualDeductions.value = {
        CA_LOAN_PAYMENTS: null,
        ITEM_LOAN_PAYMENTS: null,
        MISSING_LOAN_PAYMENTS: null,
        KIMBEL_LOAN_PAYMENTS: null,
        ELOAD_PAYMENT: 0,
        SSS_CONTRIBUTION: 0,
        SSS_CONTRIBUTION_MPF: 0,
        SSS_LOAN_PAYMENT: 0,
        SSS_CALAMITY_LOAN_PAYMENT: 0,
        SSS_CONTRIBUTION_EMP: 0,
        SSS_CONTRIBUTION_MPF_EMP: 0,
        SSS_CONTRIBUTION_EC_EMP: 0,
        PAGIBIG_LOAN_PAYMENT: 0,
        PAGIBIG_CALAMITY_LOAN_PAYMENT: 0,
        PAGIBIG_CONTRIBUTION: 0,
        PAGIBIG_CONTRIBUTION_EMP: 0,
        INSURANCE: 0,
        PH_CONTRIBUTION: 0,
        WTX: 0,
    };
    deductionInputs.value = Object.assign({}, actualDeductions.value);

    if (!selectedEmployee.value) {
        return;
    }

    loadingFlags.add('initializePayslipForSelectedEmployee');
    try {
        const initialPayslipResponse = await axios.get(route('api.payslips.initial'), {
            params: {
                TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
                EMPID: selectedEmployee.value.EMPID
            }
        });
        payslip.value = initialPayslipResponse.data.data;
        initializeSubtractSection();
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while making initial payslip.');
        scrollToTop();
    }
    loadingFlags.delete('initializePayslipForSelectedEmployee');
    isDisabled.value = false;
}

watch(selectedTimePeriod, () => { selectedEmployee.value = null; });
watch(selectedEmployee, initializePayslipForSelectedEmployee);

function initializeSubtractSection() {
    const loanPaymentMapper = loan => ({ LOANID: loan.LOANID, PAYMENT: +(loan.PAYMENT) });
    actualDeductions.value.CA_LOAN_PAYMENTS = [
        ...payslip.value.CA_LOANS_LIST.map(loanPaymentMapper),
        ...new Array(5 - payslip.value.CA_LOANS_LIST.length).fill(null).map(_ => ({ LOANID: null, PAYMENT: null }))
    ];
    actualDeductions.value.ITEM_LOAN_PAYMENTS = [
        ...payslip.value.ITEM_LOANS_LIST.map(loanPaymentMapper),
        ...new Array(5 - payslip.value.ITEM_LOANS_LIST.length).fill(null).map(_ => ({ LOANID: null, PAYMENT: null }))
    ];
    actualDeductions.value.MISSING_LOAN_PAYMENTS = [
        ...payslip.value.MISSING_LOANS_LIST.map(loanPaymentMapper),
        ...new Array(5 - payslip.value.MISSING_LOANS_LIST.length).fill(null).map(_ => ({ LOANID: null, PAYMENT: null }))
    ];
    actualDeductions.value.KIMBEL_LOAN_PAYMENTS = [
        ...payslip.value.KIMBEL_LOANS_LIST.map(loanPaymentMapper),
        ...new Array(5 - payslip.value.KIMBEL_LOANS_LIST.length).fill(null).map(_ => ({ LOANID: null, PAYMENT: null }))
    ];

    if (includeDeductions.value) {
        actualDeductions.value.ELOAD_PAYMENT = +payslip.value.ELOAD;
        actualDeductions.value.SSS_CONTRIBUTION = +payslip.value.SSS_CONTRIBUTION;
        actualDeductions.value.SSS_CONTRIBUTION_MPF = +payslip.value.SSS_CONTRIBUTION_MPF;
        actualDeductions.value.SSS_LOAN_PAYMENT = +payslip.value.SSS_LOAN;
        actualDeductions.value.SSS_CALAMITY_LOAN_PAYMENT = +payslip.value.SSS_CALAMITY;
        actualDeductions.value.SSS_CONTRIBUTION_EMP = +payslip.value.SSS_CONTRIBUTION_EMP;
        actualDeductions.value.SSS_CONTRIBUTION_MPF_EMP = +payslip.value.SSS_CONTRIBUTION_MPF_EMP;
        actualDeductions.value.SSS_CONTRIBUTION_EC_EMP = +payslip.value.SSS_CONTRIBUTION_EC_EMP;
        actualDeductions.value.PAGIBIG_LOAN_PAYMENT = +payslip.value.PAGIBIG_LOAN;
        actualDeductions.value.PAGIBIG_CALAMITY_LOAN_PAYMENT = +payslip.value.PAGIBIG_CALAMITY_LOAN;
        actualDeductions.value.PAGIBIG_CONTRIBUTION = +payslip.value.PAGIBIG_CONTRIBUTION;
        actualDeductions.value.PAGIBIG_CONTRIBUTION_EMP = +payslip.value.PAGIBIG_CONTRIBUTION_EMP;
        actualDeductions.value.PH_CONTRIBUTION = +payslip.value.PH_CONTRIBUTION;
        actualDeductions.value.INSURANCE = +payslip.value.INSURANCE;
        actualDeductions.value.WTX = +payslip.value.WTX;
        actualDeductions.value.MISSED_CONTRIB = +payslip.value.MISSED_CONTRIB;

        // For deduction inputs, initially display two decimal places.
        const loanRoundMapper = loan => ({...loan, PAYMENT: loan.PAYMENT != null ? roundTwoDecimalPlaces(loan.PAYMENT) : null});
        deductionInputs.value = JSON.parse(JSON.stringify(actualDeductions.value));
        deductionInputs.value.CA_LOAN_PAYMENTS = deductionInputs.value.CA_LOAN_PAYMENTS.map(loanRoundMapper);
        deductionInputs.value.ITEM_LOAN_PAYMENTS = deductionInputs.value.ITEM_LOAN_PAYMENTS.map(loanRoundMapper);
        deductionInputs.value.MISSING_LOAN_PAYMENTS = deductionInputs.value.MISSING_LOAN_PAYMENTS.map(loanRoundMapper);
        deductionInputs.value.KIMBEL_LOAN_PAYMENTS = deductionInputs.value.KIMBEL_LOAN_PAYMENTS.map(loanRoundMapper);
        deductionInputs.value.ELOAD_PAYMENT = roundTwoDecimalPlaces(deductionInputs.value.ELOAD_PAYMENT);
        deductionInputs.value.SSS_CONTRIBUTION = roundTwoDecimalPlaces(deductionInputs.value.SSS_CONTRIBUTION);
        deductionInputs.value.SSS_CONTRIBUTION_MPF = roundTwoDecimalPlaces(deductionInputs.value.SSS_CONTRIBUTION_MPF);
        deductionInputs.value.SSS_LOAN_PAYMENT = roundTwoDecimalPlaces(deductionInputs.value.SSS_LOAN_PAYMENT);
        deductionInputs.value.SSS_CALAMITY_LOAN_PAYMENT = roundTwoDecimalPlaces(deductionInputs.value.SSS_CALAMITY_LOAN_PAYMENT);
        deductionInputs.value.SSS_CONTRIBUTION_EMP = roundTwoDecimalPlaces(deductionInputs.value.SSS_CONTRIBUTION_EMP);
        deductionInputs.value.SSS_CONTRIBUTION_MPF_EMP = roundTwoDecimalPlaces(deductionInputs.value.SSS_CONTRIBUTION_MPF_EMP);
        deductionInputs.value.SSS_CONTRIBUTION_EC_EMP = roundTwoDecimalPlaces(deductionInputs.value.SSS_CONTRIBUTION_EC_EMP);
        deductionInputs.value.PAGIBIG_LOAN_PAYMENT = roundTwoDecimalPlaces(deductionInputs.value.PAGIBIG_LOAN_PAYMENT);
        deductionInputs.value.PAGIBIG_CALAMITY_LOAN_PAYMENT = roundTwoDecimalPlaces(deductionInputs.value.PAGIBIG_CALAMITY_LOAN_PAYMENT);
        deductionInputs.value.PAGIBIG_CONTRIBUTION = roundTwoDecimalPlaces(deductionInputs.value.PAGIBIG_CONTRIBUTION);
        deductionInputs.value.PAGIBIG_CONTRIBUTION_EMP = roundTwoDecimalPlaces(deductionInputs.value.PAGIBIG_CONTRIBUTION_EMP);
        deductionInputs.value.PH_CONTRIBUTION = roundTwoDecimalPlaces(deductionInputs.value.PH_CONTRIBUTION);
        deductionInputs.value.INSURANCE = roundTwoDecimalPlaces(deductionInputs.value.INSURANCE);
        deductionInputs.value.WTX = roundTwoDecimalPlaces(deductionInputs.value.WTX);
    } else {
        actualDeductions.value.ELOAD_PAYMENT = 0
        actualDeductions.value.SSS_CONTRIBUTION = 0;
        actualDeductions.value.SSS_CONTRIBUTION_MPF = 0;
        actualDeductions.value.SSS_LOAN_PAYMENT = 0;
        actualDeductions.value.SSS_CALAMITY_LOAN_PAYMENT = 0;
        actualDeductions.value.SSS_CONTRIBUTION_EMP = 0;
        actualDeductions.value.SSS_CONTRIBUTION_MPF_EMP = 0;
        actualDeductions.value.SSS_CONTRIBUTION_EC_EMP = 0;
        actualDeductions.value.PAGIBIG_LOAN_PAYMENT = 0;
        actualDeductions.value.PAGIBIG_CALAMITY_LOAN_PAYMENT = 0;
        actualDeductions.value.PAGIBIG_CONTRIBUTION = 0;
        actualDeductions.value.PAGIBIG_CONTRIBUTION_EMP = 0;
        actualDeductions.value.PH_CONTRIBUTION = 0;
        actualDeductions.value.INSURANCE = 0;
        actualDeductions.value.WTX = 0;
        actualDeductions.value.MISSED_CONTRIB = 0;
        
        deductionInputs.value = Object.assign({}, actualDeductions.value);
    }
}

const submitForm = async () => {
    let payslip = {
        EMPID: selectedEmployee.value.EMPID,
        PAYTYPE: selectedTimePeriod.value.PAYTYPE,
        TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
        INCLUDE_DEDUCTIONS: includeDeductions.value,
        CA_LOAN_PAYMENTS: actualDeductions.value.CA_LOAN_PAYMENTS?.filter(payment => payment.LOANID != null),
        ITEM_LOAN_PAYMENTS: actualDeductions.value.ITEM_LOAN_PAYMENTS?.filter(payment => payment.LOANID != null),
        MISSING_LOAN_PAYMENTS: actualDeductions.value.MISSING_LOAN_PAYMENTS?.filter(payment => payment.LOANID != null),
        KIMBEL_LOAN_PAYMENTS: actualDeductions.value.KIMBEL_LOAN_PAYMENTS?.filter(payment => payment.LOANID != null),
        ELOAD_PAYMENT: actualDeductions.value.ELOAD_PAYMENT,
        SSS_CONTRIBUTION: actualDeductions.value.SSS_CONTRIBUTION,
        SSS_CONTRIBUTION_MPF: actualDeductions.value.SSS_CONTRIBUTION_MPF,
        SSS_LOAN_PAYMENT: actualDeductions.value.SSS_LOAN_PAYMENT,
        SSS_CALAMITY_LOAN_PAYMENT: actualDeductions.value.SSS_CALAMITY_LOAN_PAYMENT,
        SSS_CONTRIBUTION_EMP: actualDeductions.value.SSS_CONTRIBUTION_EMP,
        SSS_CONTRIBUTION_MPF_EMP: actualDeductions.value.SSS_CONTRIBUTION_MPF_EMP,
        SSS_CONTRIBUTION_EC_EMP: actualDeductions.value.SSS_CONTRIBUTION_EC_EMP,
        PAGIBIG_LOAN_PAYMENT: actualDeductions.value.PAGIBIG_LOAN_PAYMENT,
        PAGIBIG_CALAMITY_LOAN_PAYMENT: actualDeductions.value.PAGIBIG_CALAMITY_LOAN_PAYMENT,
        PAGIBIG_CONTRIBUTION: actualDeductions.value.PAGIBIG_CONTRIBUTION,
        PAGIBIG_CONTRIBUTION_EMP: actualDeductions.value.PAGIBIG_CONTRIBUTION_EMP,
        INSURANCE: actualDeductions.value.INSURANCE,
        PH_CONTRIBUTION: actualDeductions.value.PH_CONTRIBUTION,
        WTX: actualDeductions.value.WTX,
    };

    // Prompt to create CA Loan, but it will be created on backend
    if (caOffset.value > 0 && !confirm("Create Cash Advance Loan for Employee?")) {
        return;
    }

    loadingFlags.add('submitPayslip');
    try {
        await axios.post(route('api.payslips.store'), payslip);
        addFlashMessage('SUCCESS', `Successfully created payslip, payments, and costs
            for employee ${selectedEmployee.value.FNAME} ${selectedEmployee.value.LNAME}.`);

        employeesTableRefreshFlag.value = !employeesTableRefreshFlag.value;
        loansRefreshFlag.value = !loansRefreshFlag.value;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, 'Error while creating payslip.');
    }
    loadingFlags.delete('submitPayslip');
    isDisabled.value = true;
    scrollToTop();
};
</script>
