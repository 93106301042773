<style scoped>
#dependentsTableContainer {
    max-height: 350px;
    overflow-y: auto;
}

#dependentsTableContainer table thead {
    position: sticky;
    top: 0;
    background-color: lightslategrey;
    color: #fff;
}
</style>

<template>
    <form @submit.prevent="updateEmployee" class="mb-3">
        <div class="row g-3 mb-3">
            <div class="col-xl-3 col-lg-4">
                <fieldset class="h-100">
                    <legend>
                        <i class="icon ph-bold ph-user me-2"></i> Selected Employee
                    </legend>
                    <div class="text-end mb-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                                (_) => {
                                    isSelectEmployeePopupOpen = true;
                                }
                            "
                        >
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select Employee
                        </button>
                    </div>
                    <FormInput
                        type="text"
                        label="ID"
                        v-model="employee.EMPID"
                        id-prefix="empid"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                    <FormInput
                        type="text"
                        label="First Name"
                        v-model="employee.FNAME"
                        id-prefix="fname"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                    <FormInput
                        type="text"
                        label="Middle Name"
                        v-model="employee.MNAME"
                        id-prefix="fname"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                    <FormInput
                        type="text"
                        label="Last Name"
                        v-model="employee.LNAME"
                        id-prefix="fname"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                </fieldset>
            </div>
            <div class="col-xl-9 col-lg-8">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-user-list me-2"></i> Employee Details
                    </legend>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <FormSelect
                                label="Civil Status"
                                v-model="employee.CIVIL_STATUS"
                                :options="civilStatuses"
                                :option-to-string="(civilStatus) => civilStatus.verbose"
                                :option-value="(civilStatus) => civilStatus.value"
                                id-prefix="civilStatus"
                                select-class="form-select-sm"
                                :errors="employeeErrors.CIVIL_STATUS"
                                @keydown-enter="focusNextInput('presentAddressFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 mb-2">
                            <FormInput
                                type="text"
                                label="Present Address"
                                v-model="employee.PRESENT_ADDRESS"
                                id-prefix="presentAddress"
                                input-class="form-control-sm"
                                :errors="employeeErrors.PRESENT_ADDRESS"
                                @keydown-enter="focusNextInput('permanentAddressFormInput')"
                            />
                        </div>
                        <div class="col-lg-6 mb-2">
                            <FormInput
                                type="text"
                                label="Permanent Address"
                                v-model="employee.PERMANENT_ADDRESS"
                                id-prefix="permanentAddress"
                                input-class="form-control-sm"
                                :errors="employeeErrors.PERMANENT_ADDRESS"
                                @keydown-enter="focusNextInput('spouseNameFormInput')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 mb-2">
                            <FormInput
                                type="text"
                                label="Spouse Name"
                                v-model="employee.SPOUSE_NAME"
                                id-prefix="spouseName"
                                input-class="form-control-sm"
                                :errors="employeeErrors.SPOUSE_NAME"
                                @keydown-enter="focusNextInput('motherMaidenNameFormInput')"
                            />
                        </div>
                        <div class="col-lg-6 mb-2">
                            <FormInput
                                type="text"
                                label="Mother's Full Maiden Name"
                                v-model="employee.MOTHER_MAIDEN_NAME"
                                id-prefix="motherMaidenName"
                                input-class="form-control-sm"
                                :errors="employeeErrors.MOTHER_MAIDEN_NAME"
                                @keydown-enter="focusNextInput('updateEmployeeButton')"
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary me-1 btn-sm"
                        id="updateEmployeeButton"
                        @click="updateEmployee"
                        :disabled="employee.EMPID == null"
                    >
                        <i class="icon ph-bold ph-note-pencil me-2"></i>Update Details
                    </button>
                    <button
                        type="reset"
                        class="btn btn-outline-secondary btn-sm"
                        @click="resetEmployeeDetails"
                    >
                        <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Reset Fields
                    </button>
                </fieldset>
            </div>
        </div>
    </form>
    <div class="row g-3">
        <div class="col-xl-3 col-lg-4">
            <form @submit.prevent="saveDependent">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-plus-minus me-2"></i>Add/Remove Dependent
                    </legend>
                    <FormInput
                        type="text"
                        label="Name"
                        v-model="dependent.NAME"
                        id-prefix="name"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.NAME"
                        @keydown-enter="focusNextInput('genderFormInput')"
                    />
                    <FormSelect
                        label="Gender"
                        v-model="dependent.GENDER"
                        :options="genders"
                        :option-to-string="(gender) => gender.verbose"
                        :option-value="(gender) => gender.value"
                        id-prefix="gender"
                        select-class="form-select-sm"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.GENDER"
                        @keydown-enter="focusNextInput('dependentCivilStatusFormInput')"
                    />
                    <FormSelect
                        label="Civil Status"
                        v-model="dependent.STATUS"
                        :options="civilStatuses"
                        :option-to-string="(civilStatus) => civilStatus.verbose"
                        :option-value="(civilStatus) => civilStatus.value"
                        id-prefix="dependentCivilStatus"
                        select-class="form-select-sm"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.STATUS"
                        @keydown-enter="focusNextInput('birthdateFormInput')"
                    />
                    <FormInput
                        type="date"
                        label="Birthdate"
                        v-model="dependent.BIRTHDATE"
                        id-prefix="birthdate"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.BIRTHDATE"
                        @keydown-enter="focusNextInput('addDependentButton')"
                    />
                    <div class="d-grid gap-1 mt-4">
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm"
                            id="addDependentButton"
                            :disabled="employee.EMPID == null || isSaving"
                        >
                            <i class="icon ph-bold ph-plus me-2"></i>Add Dependent
                            <div
                                v-if="isSaving"
                                class="spinner-border text-light"
                                role="status"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            :disabled="
                                employee.EMPID == null ||
                                dependent.id == null ||
                                isDeleting
                            "
                            @click="removeDependent"
                        >
                            <i class="icon ph-bold ph-trash me-2"></i>Remove Dependent
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="dependent.id == null"
                            @click="(_) => {
                                isAuditsPopupOpen = true;
                            }"
                        >
                            <i class="icon ph-bold ph-list me-2"></i>View Audits
                        </button>
                        <button
                            type="reset"
                            class="btn btn-outline-secondary btn-sm"
                            @click="resetDependentFields"
                        >
                            <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Reset Fields
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="col-xl-9 col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-users-three me-2"></i> Employee Dependents
                </legend>
                <div class="table-responsive" id="dependentsTableContainer">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Status</th>
                                <th>Birthdate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="employee.EMPID == null">
                                <td colspan="4" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>Please select an employee.
                                    </div>
                                </td>
                            </tr>
                            <tr v-else-if="dependents?.length == 0">
                                <td colspan="4" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>No dependents.
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="dependentEntry in dependents"
                                :class="{ 'bg-primary text-white': dependent.id == dependentEntry.id }"
                                @click="employeeDependentRowOnClick(dependentEntry)"
                            >
                                <td>{{ dependentEntry.NAME }}</td>
                                <td>{{ dependentEntry.GENDER }}</td>
                                <td>{{ dependentEntry.STATUS }}</td>
                                <td>{{ dependentEntry.BIRTHDATE }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
    </div>

    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (employee) => {
                onEmployeeSelect(employee);
                isSelectEmployeePopupOpen = false;
            }
        "
    />

    <AuditsPopup
        :is-open="isAuditsPopupOpen"
        @close="(_) => {
            isAuditsPopupOpen = false;
        }"
        auditable-type="App\Models\EmployeeDependent"
        :auditable-id="dependent.id"
    />
</template>

<script setup>
import { ref } from "vue";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

import axios from "axios";

import focusNextInput from "../../../utils/focusnext";
import handleFetchErrors from "../../../utils/handleFetchErrors";
import scrollToTop from "../../../utils/scrollToTop";

import { useFlashMessages } from "../../../composables/flashmessages";
import AuditsPopup from "@/components/utils/popups/AuditsPopup.vue";

const { addFlashMessage } = useFlashMessages();

const employeeErrors = ref({});
const errors = ref({});

const genders = [
    { value: "F", verbose: "Female" },
    { value: "M", verbose: "Male" },
];

const civilStatuses = [
    { value: "S", verbose: "Single" },
    { value: "M", verbose: "Married" },
    { value: "W", verbose: "Widowed" },
    { value: "X", verbose: "Separated" },
];

const dependents = ref();

const employee = ref({});
const dependent = ref({});

const isSelectEmployeePopupOpen = ref(false);
const isAuditsPopupOpen = ref(false);

const isEmployeeUpdating = ref(false);
const isSaving = ref(false);
const isDeleting = ref(false);

const onEmployeeSelect = (selectedEmployee) => {
    employee.value = selectedEmployee;
    fetchDependents();
};

const updateEmployee = async () => {
    isEmployeeUpdating.value = true;
    employeeErrors.value = {};
    try {
        const updateEmployeeResponse = await axios.patch(
            route("api.employees.update", {
                employee: employee.value.EMPID,
            }),
            employee.value
        );
        addFlashMessage("SUCCESS", "Successfully updated employee.");
        employee.value = updateEmployeeResponse.data.data;
    } catch (e) {
        console.log(e);
        employeeErrors.value = handleFetchErrors(
            e,
            "Error while updating employee."
        );
    }
    scrollToTop();
    isEmployeeUpdating.value = false;
};

function resetEmployeeDetails() {
    employee.value = {
        ...employee.value,
        CIVIL_STATUS: null,
        PRESENT_ADDRESS: null,
        PERMANENT_ADDRESS: null,
        SPOUSE_NAME: null,
        MOTHER_MAIDEN_NAME: null,
    };
}

async function fetchDependents() {
    try {
        const dependentsResponse = await axios.get(
            route("api.employees.dependents.index", {
                employee: employee.value.EMPID,
            })
        );
        dependents.value = dependentsResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching dependents.");
        scrollToTop();
    }
}

function employeeDependentRowOnClick(clickedDependent) {
    dependent.value = clickedDependent;
}

const saveDependent = async () => {
    isSaving.value = true;
    try {
        await axios.post(
            route("api.employees.dependents.store", {
                employee: employee.value.EMPID,
            }),
            dependent.value
        );
        addFlashMessage("SUCCESS", "Successfully added dependent.");
        fetchDependents();
        dependent.value = {};
        errors.value = {};
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(e, "Error while saving dependent.");
    }
    scrollToTop();
    isSaving.value = false;
};

const removeDependent = async (id) => {
    isDeleting.value = true;
    try {
        await axios.delete(
            route("api.employees.dependents.destroy", {
                employee: employee.value.EMPID,
                dependent: dependent.value.id,
            })
        );
        addFlashMessage("SUCCESS", "Successfully removed dependent.");
        fetchDependents();
        dependent.value = {};
        errors.value = {};
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while removing dependent.");
    }
    scrollToTop();
    isDeleting.value = false;
};

function resetDependentFields() {
    dependent.value = {};
}
</script>
