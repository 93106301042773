<template>
    <div class="is-dashboard py-2">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="card qoute-container rounded bg-white px-5 py-2">
                    <div class="row py-2" v-html="quoteOfTheDayHTML" />
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <FullCalendar
                    :options="calendarOptions"
                    :fixedWeekCount="false"
                    :button-text="{ month: 'Month' }"
                    class="dashboard-calendar mx-auto rounded-2 shadow"
                />
            </div>
            <EventsTodaySection class="col-lg-4" />
        </div>
    </div>

    <ViewEventDetailsPopup
        :is-open="isViewEventDetailsPopupOpen"
        @close="
            (_) => {
                isViewEventDetailsPopupOpen = false;
            }
        "
        v-model="companyEvent"
    />

    <CreateOrUpdatePersonalNoteOnDatePopup
        :is-open="isCreateOrUpdatePersonalNoteOnDatePopupOpen"
        @close="
            (_) => {
                isCreateOrUpdatePersonalNoteOnDatePopupOpen = false;
            }
        "
        :date="dateBeingViewed"
        v-model="personalNote"
        @success="
            (_) => {
                fetchCompanyEventsAndPersonalNotes();
                isCreateOrUpdatePersonalNoteOnDatePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanyEvents } from "@/composables/data/companyEvents";
import { usePersonalNotes } from "@/composables/data/personalNotes";

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import rrulePlugin from "@fullcalendar/rrule";
import moment from "moment";
import xss from "xss";

import ViewEventDetailsPopup from "@/components/utils/popups/ViewEventDetailsPopup.vue";
import CreateOrUpdatePersonalNoteOnDatePopup from "@/components/utils/popups/CreateOrUpdatePersonalNoteOnDatePopup.vue";
import EventsTodaySection from "./EventsTodaySection.vue";
import axios from "axios";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const quoteOfTheDayHTML = ref(null);

const { companyEvents, getCompanyEvents } = useCompanyEvents();
const { personalNotes, getLoggedInUserPersonalNotes } = usePersonalNotes();

const calendarOptions = ref({
    plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrap5Plugin,
        rrulePlugin,
    ],
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    themeSystem: "bootstrap5",
    initialView: "dayGridMonth",
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    datesSet: calendarDatesSet,
    eventClick: calendarEventClick,
    dateClick: calendarDateClick,
    events: [],
});

const { companyEvent, getCompanyEvent } = useCompanyEvents();
const isViewEventDetailsPopupOpen = ref(false);

const dateBeingViewed = ref(null);
const { personalNote, getLoggedInUserPersonalNote } = usePersonalNotes();
const isCreateOrUpdatePersonalNoteOnDatePopupOpen = ref(false);

const calendarStartDate = ref(null);
const calendarEndDate = ref(null);

const COMPANY_EVENT_TYPE_COLOR_MAPPING = {
    BIRTHDAY: "#eb2f61",
    ANNIV: "#289428",
    REGULAR: "#1982ff",
};

async function fetchQuoteOfTheDay() {
    loadingFlags.add("fetchQuoteOfTheDay");
    try {
        const response = await axios.get("https://www.technomancer.biz/swirl/quote/quote.php", {
            headers: null,
        });
        quoteOfTheDayHTML.value = xss(response.data, {
            onTagAttr: function (tag, name, value, isWhiteAttr) {
                if ((tag == 'img' && name == 'src') || (tag == 'div' && name == 'class')) {
                    if (value.indexOf('"') == -1) {
                        return `${name}="${value}"`;
                    }
                }
            }
        });
    } catch (e) {
        console.log(e);
    }
    loadingFlags.delete("fetchQuoteOfTheDay");
    window.xss = xss;
}

onMounted(fetchQuoteOfTheDay);

async function fetchCompanyEventsAndPersonalNotes() {
    loadingFlags.add("fetchCompanyEventsOnDashboard");
    try {
        await getCompanyEvents({
            start_date: calendarStartDate.value,
            end_date: calendarEndDate.value,
        });

        await getLoggedInUserPersonalNotes({
            start_date: calendarStartDate.value,
            end_date: calendarEndDate.value,
        });

        calendarOptions.value.events = [
            ...companyEvents.value.map((companyEvent) => ({
                id: ["EVENT", companyEvent.id, companyEvent.event_date].join(
                    "_"
                ),
                title: companyEvent.name,
                start: companyEvent.event_date,
                backgroundColor: COMPANY_EVENT_TYPE_COLOR_MAPPING[companyEvent.type],
                borderColor: COMPANY_EVENT_TYPE_COLOR_MAPPING[companyEvent.type],
                rrule:
                    companyEvent.is_recurring && !companyEvent.is_duplicate
                        ? {
                              freq: "yearly",
                              interval: 1,
                              dtstart: moment
                                  .utc(companyEvent.event_date)
                                  .format("YYYY-MM-DD"),
                          }
                        : null,
            })),
            ...personalNotes.value.map((personalNote) => ({
                id: ["NOTE", personalNote.id, personalNote.note_date].join("_"),
                title: `(NOTE) ${personalNote.name}`,
                start: personalNote.note_date,
                backgroundColor: "#2a1770",
                borderColor: "#2a1770",
                rrule:
                    personalNote.recurrence_type != null &&
                    !personalNote.is_duplicate
                        ? {
                              freq: personalNote.recurrence_type.toLowerCase(),
                              interval: 1,
                              dtstart: moment
                                  .utc(personalNote.note_date)
                                  .format("YYYY-MM-DD"),
                          }
                        : null,
            })),
        ];
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchCompanyEventsOnDashboard");
}

async function calendarDatesSet(dateInfo) {
    calendarStartDate.value = dateInfo.startStr.split("T", 1)[0];
    calendarEndDate.value = dateInfo.endStr.split("T", 1)[0];
    fetchCompanyEventsAndPersonalNotes();
}

async function calendarEventClick(eventClickInfo) {
    const eventId = eventClickInfo.event.id;

    if (eventId.startsWith("EVENT")) {
        loadingFlags.add("fetchCompanyEvent");
        try {
            await getCompanyEvent(Number(eventId.split("_")[1]));
            isViewEventDetailsPopupOpen.value = true;
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("fetchCompanyEvent");
    } else if (eventId.startsWith("NOTE")) {
        loadingFlags.add("fetchPersonalNote");
        try {
            await getLoggedInUserPersonalNote(Number(eventId.split("_")[1]));
            isCreateOrUpdatePersonalNoteOnDatePopupOpen.value = true;
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("fetchPersonalNote");
    }
}

function calendarDateClick(dateClickInfo) {
    dateBeingViewed.value = dateClickInfo.dateStr;
    personalNote.value = null;
    isCreateOrUpdatePersonalNoteOnDatePopupOpen.value = true;
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
.dashboard-calendar {
    max-width: 1400px;
    padding: 1rem;
    background: #fff;
}

:deep(.fc-scrollgrid-sync-table) {
    height: auto !important;
}

:deep(.fc-daygrid-day) {
    height: 98px;
}

:deep(.fc-scroller-liquid-absolute) {
    position: relative;
    height: auto;
}

:deep(.fc .fc-view-harness-active > .fc-view) {
    position: relative;
}

:deep(.fc .fc-view-harness) {
    height: auto !important;
}

:deep(.fc-theme-bootstrap5 td) {
    background-color: #fff;
}

:deep(.fc .fc-scrollgrid-section),
:deep(.fc .fc-scrollgrid-section table),
:deep(.fc .fc-scrollgrid-section > td),
:deep(.fc .fc-daygrid-body) {
    width: 100% !important;
}

:deep(.fc-h-event) {
    background-color: #668cd9;
    border: 1px solid #668cd9;
    display: block;
}

:deep(.fc-h-event .fc-event-title),
:deep(.fc-event) {
    font-size: 12px;
    line-height: 1;
    margin-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:deep(.fc .fc-daygrid-day.fc-day-today) {
    background-color: #fff7d7;
}

:deep(.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number) {
    background: #2a1770;
    border-radius: 50%;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    font-weight: bold;
}

:deep(.fc-h-event .fc-event-title-container) {
    display: flex;
    padding: 3px;
}

:deep(.fc-direction-ltr .fc-daygrid-event .fc-event-time) {
    font-size: 13px;
    margin-right: 5px;
}

:deep(.fc-direction-ltr .fc-daygrid-event .fc-event-title) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:deep(.fc-direction-ltr .fc-daygrid-event) {
    border-radius: 7px;
}

:deep(.fc-col-header-cell) {
    text-transform: uppercase;
}

:deep(.fc .fc-daygrid-day-top) {
    justify-content: center;
}

:deep(.fc-toolbar-title) {
    font-family: "Mynerve", cursive;
    color: #2a1770;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
}
</style>
