import { ref } from "vue";
import constructFetchError from "./constructFetchError";
import axios from "axios";

export function useAudits() {
    const audits = ref(null);
    const totalRecords = ref(null);

    const getAudits = async (params) => {
        try {
            const getAuditsResponse = await axios.get(route('api.audits.index', params));
            audits.value = getAuditsResponse.data.data;
            totalRecords.value = getAuditsResponse.data.meta?.total ?? audits.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching audits.');
        }
    };

    return {
        audits,
        totalRecords,
        getAudits,
    };
}
