<style scoped>
img {
    max-height: 200px;
}
</style>

<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-image me-2"></i>Employee Image</legend>
        <div v-if="employee != null" class="text-center">
            <span
                v-if="isImageLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            />
            <img :src="imageURL"
                :alt="'Employee ' + (employee?.EMPID ?? '') + ' image'"
                class="img-fluid"
                @load=" (_) => { isImageLoading = false; } "
                @error="setDefaultImage"
            />
        </div>
        <div v-else class="text-center no-style-message">
            Please select an employee.
        </div>
        <slot name="footer"></slot>
    </fieldset>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useToastsStore } from '@/stores/toasts';
import { useEmployees } from '@/composables/data/employees';

const toasts = useToastsStore();

const props = defineProps({
    employeeId: Number,
    class: String,
});

const { employee, getEmployee } = useEmployees();

const imageURL = ref(null);
const isImageLoading = ref(false);

watch(() => props.employeeId, async () => {
    if (!props.employeeId) {
        setDefaultImage();
        return;
    }

    try {
        isImageLoading.value = true;
        await getEmployee(props.employeeId, {
            include_relations: 'image'
        });

        if (!employee.value.image?.url) {
            setDefaultImage();
        } else {
            imageURL.value = employee.value.image?.url;
        }
    } catch (e) {
        console.log(e);
        setDefaultImage();
        if (!e.response || e.response.status != 404) {
            toasts.add('ERROR', 'Error', e.message);
        }
    }
});

function setDefaultImage() {
    isImageLoading.value = true;
    imageURL.value = `${Ziggy.url}/img/bagnoimage.jpg`;
}
</script>
